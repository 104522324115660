<template>
  <v-container>
    <v-row>
      <v-col>
        <v-chip-group
          active-class="primary"
          mandatory
        >
          <v-chip
            v-for="(g, index) of games"
            :key="index"
            @click="selectGame(index)"
          >
            {{ g.name }}
          </v-chip>
        </v-chip-group>

        <v-row class="pt-4">
          <v-col lg="4" md="4" cols="12" class="pb-0">
            <v-select
              v-model="ttvLanguage"
              :items="languages"
              label="Language"
              outlined
              small-chips
              deletable-chips
              @input="changedLanguage"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pt-0">
            <v-checkbox v-model="liveCb" label="Live Profiles" @change="switchLiveCb" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="y of ttvFilter" :key="y.id" lg="3" md="6">
        <v-card elevation="12" width="440" class="" style="border-radius: 25px;">
          <v-hover v-slot="{ hover }">
            <router-link :class="`${hover ? '' : 'white--text' }`" :to="`/stream/${y.user_login}?game=` + curGame">
              <v-img width="440" height="248" :src="getThumb2(y.user_login,440,248)" style="border-top-left-radius: 25px; border-top-right-radius: 25px;">

                <v-col class="d-flex justify-end">
                  <p class="d-flex align-center" style="background: rgb(253,29,29); background: linear-gradient(130deg, rgba(253,29,29,1) 10%, rgba(252,176,69,1) 100%); width: 70px; border-radius: 50px">
                    <v-icon right class="white--text">mdi-access-point</v-icon> <span class="white--text">Live</span>
                  </p>
                </v-col>
                <v-col class="d-flex justify-center align-center pt-8">
                  <v-btn
                    v-if="hover"
                    style="background: rgb(253,29,29); background: linear-gradient(130deg, rgba(253,29,29,1) 10%, rgba(252,176,69,1) 100%);"
                    icon
                    outlined
                    x-large
                    class="white--text"
                    :to="`/stream/${y.user_login}?game=` + curGame"
                  >
                    <v-icon dark>
                      mdi-play
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-img>
              <v-card-title class="d-block text-truncate py-1" v-text="y.title" />
            </router-link>
          </v-hover>
          <v-card-text class="pb-0">
            <p class="font-weight-thin ma-0">{{ y.user_login }}</p>
            <p>{{ y.game_name }}</p> <v-img height="32" width="32" :src="`https://www.countryflags.io/${flagCode(y.language)}/shiny/32.png`" />
            <p><span style="color: orange">{{ Number(y.viewer_count).toLocaleString() }}</span> Viewers</p><br>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapActions,
    mapMutations,
    mapState,
  } from 'vuex'
  import Vue from 'vue'
  import LoadScript from 'vue-plugin-load-script'
  Vue.use(LoadScript)
  export default {
    name: 'GameStreams',
    data () {
      return {
        ttvList: [],
        // ttvLanguage: [],
        liveCb: false,
        curGame: 'lol',
        gamesName: ['League of Legends', 'Counter-Strike: Global Offensive', 'Teamfight Tactics'],
      }
    },
    computed: {
      ttvLanguage: {
        get () {
          return this.ttvLanguages
        },
        set (value) {
          this.setLanguages(value)
        },
      },
      ttvFilter () {
        var list = []
        for (var ttv of this.ttvList) {
          if (this.ttvLanguages.length === 0 || this.ttvLanguages.includes(ttv.language)) list.push(ttv)
        }
        // ttvLanguage
        return list
      },
      languages () {
        return [{ text: 'English', value: 'en' },
                { text: 'Portuguese', value: 'pt' },
                { text: 'Spanish', value: 'es' },
                { text: 'German', value: 'de' },
                { text: 'Arabian', value: 'ar' },
                { text: 'French', value: 'fr' },
                { text: 'Korean', value: 'ko' },
                { text: 'Vietnan', value: 'cs' },
                { text: 'Russian', value: 'ru' },
                { text: 'Chinese', value: 'zh' },
                { text: 'Japan', value: 'ja' },
                { text: 'Turkish', value: 'tr' },
                { text: 'Hungary', value: 'hu' },
                { text: 'Poland', value: 'pl' },
                { text: 'Greek', value: 'el' },
        ]
      },
      ...mapState('platform', ['mainHost', 'games']),
      ...mapState('cfg', ['ttvLanguages']),
    },
    watch: {
      node () {
        this.getTtv()
        console.log('nchange')
      },
    },
    beforeCreate () {
      Vue.loadScript('https://player.twitch.tv/js/embed/v1.js')
    },
    async mounted () {
      // this.curGame = Object.keys(this.games)[0]
      await this.getTtv()
    },
    methods: {
      flagCode (language) {
        var ret = language
        if (ret === 'ko') ret = 'kr'
        if (ret === 'en') ret = 'us'
        if (ret === 'zh') ret = 'cn'
        if (ret === 'ja') ret = 'jp'
        if (ret === 'pt') ret = 'br'
        return ret
      },
      changedLanguage () {
        this.getTtv()
      },
      async switchLiveCb () {
        console.log('switch live cb')
        this.getTtv()
      },
      ...mapMutations('cfg', ['setLanguages']),
      ...mapActions('node', ['setNode', 'grabStreams']),
      getThumb (y, w, h) {
        var url = y.thumbnail_url
        return url.replace('-{width}x{height}.jpg', '-' + w + 'x' + h + '.jpg')
      },
      getThumb2 (name, w, h) {
        return 'https://static-cdn.jtvnw.net/previews-ttv/live_user_' + name + '-' + w + 'x' + h + '.jpg'
      },
      selectGame (game) {
        this.curGame = game
        this.getTtv()
      },
      async getTtv () {
        var ret = (await this.$http.get(this.mainHost + '/ttv?game=' + this.curGame + '&live=' + this.liveCb + '&languages=' + this.ttvLanguages)).data
        this.ttvList = ret.data
      },
    },
  }
</script>
